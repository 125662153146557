








































































































































































































































import Vue from 'vue';
import HotelClassificationBusinessListResourceViewModel from '@/src/services/viewModel/resource/HotelClassificationBusinessListResourceViewModel';

export default Vue.extend({
  auth: true,
  data: () => ({
    isSubscribed: false,
    classificationList: [] as HotelClassificationBusinessListResourceViewModel[],
    requestError: undefined,
  }),
  computed: {
    hasClassifications() {
      return this.classificationList.length > 0;
    },
    tenant() {
      return this.$route.params.tenant;
    },
    workspace() {
      return this.$route.params.workspace;
    },
  },
  async mounted() {
    const id = this.$service.api.memberships.getMyMembershipId();
    this.isSubscribed = this.$service.api.hotelClassifications.getHasHotelClassification();
    if (id !== undefined) {
      try {
        const classification = await this.$service.api.hotelClassifications.getMyHotelClassificationBusinesses();
        this.classificationList = classification.items;
      } catch (error: any) {
        this.requestError = error;
      }
    }
  },
  methods: {
    async phoneCall(phoneNumber: string) {
      const telLink = `tel:${phoneNumber}`;
      window.location.href = telLink;
    },
  },
});
